
























import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import { RouterNames } from "@/router/routernames";
import alimentoModule from "@/store/modules/visualizacion_dieta/alimento-module";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue"),
  },
})
export default class Alimentos_lista extends Vue {
  //public ColumnasAlimentos: ClassColumnDataTable[] = [];

  created() {
    alimentoModule.getall_alimentos_lista();
  }

  public get datasource() {
    return alimentoModule.all_alimentos;
  }

  public get Columns() {
    var ColumnasAlimentos = [];
    ColumnasAlimentos.push(
      new ClassColumnDataTable(
        "nombre",
        "Nombre",
        datatypes.string,
        true,
        datatypes.maxlength
      )
    );
    ColumnasAlimentos.push(
      new ClassColumnDataTable(
        "porcentaje_gr",
        "% gr.",
        datatypes.number,
        true,
        datatypes.maxlength
      )
    );
    ColumnasAlimentos.push(
      new ClassColumnDataTable(
        "porcentaje_glucidos",
        "% Hc",
        datatypes.number,
        true,
        datatypes.maxlength
      )
    );
    ColumnasAlimentos.push(
      new ClassColumnDataTable(
        "porcentaje_protein",
        "% Prt.",
        datatypes.number,
        true,
        datatypes.maxlength
      )
    );
    return ColumnasAlimentos;
  }

  public editarDieta(e: number) {
    this.abrir_formulario(e.toString());
  }

  abrir_formulario(id: string) {
    this.$router.push({
      name: RouterNames.alimentoficha,
      params: { id: id },
    });
  }
}
